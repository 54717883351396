* {
  box-sizing: border-box;
}

body {
  /*background: black;*/
  font-family: 'Bahnschrift';
  font-size: 20px;
  color: white;
  overflow: hidden;
}

[data-nextjs-dialog-content] {
  display: none;
}
